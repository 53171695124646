import './App.css';
import React, {useEffect, useState} from "react";
import {Menu} from "./component/main/Menu";
import {Navigate, Route, Routes} from "react-router-dom";
import {HeaderBar} from "./component/main/HeaderBar";
import ServicesMain from "./component/servicesPages/ServicesMain";
import Login from "./security/Login";
import Callback from "./security/Callback";
import ClientList from "./component/client/ClientList";
import TrainerList from "./component/trainer/TrainerList";
import CalendarUser from "./component/calendar/CalendarUser";
import UserCenter from "./component/center/UserCenter";
import CalendarAdmin from "./component/calendar/CalendarAdmin";
import CenterList from "./component/center/CenterList";
import Profile from "./component/profile/Profile";
import useTokenRefresher from "./security/useTokenRefresher";

const PrivateRoute = ({element: Element, ...rest}) => {
    // console.log("PrivateRoute", localStorage.getItem('access_token'))
    const isAuthenticated = localStorage.getItem('access_token') != null;
    return isAuthenticated ? <Element {...rest} /> : <Navigate to="/login"/>;
};

function App() {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedPage, setSelectedPage] = useState("Centers");
    const [selectedPageTitle, setSelectedPageTitle] = useState("Centros");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useTokenRefresher();

    const getCalendarComponent = () => {
        const roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : [];
        return roles.includes('ADMIN') ? CalendarAdmin : CalendarUser;
    };

    const getCenterComponent = () => {
        const roles = localStorage.getItem('roles') ? localStorage.getItem('roles') : [];
        return roles.includes('ADMIN') ? CenterList : UserCenter;
        // return roles.includes('ADMIN') ? CenterList : UserCenter;
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="app-container d-flex">
            <div className="inner-container">
                <Menu
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                    setSelectedPageTitle={setSelectedPageTitle}
                />
                {/*<MainContainer collapsed={collapsed} selectedPage={selectedPage} username={userName}/>*/}

                <div className={`main-content ${collapsed ? "expanded" : ""}`}>
                    {/*<HeaderBar selectedPage={selectedPageTitle} username={userName}/>*/}
                    {localStorage.getItem('access_token') &&
                        <HeaderBar selectedPage={selectedPageTitle} isMobile={isMobile}/>}
                    <h2 className={"d-flex"} style={{marginTop: '5rem'}}>{selectedPageTitle}</h2>
                    <div className={"content"}>
                        <Routes>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/" element={<Navigate to={isMobile ? "/calendar" : "/centers"}/>}/>
                            <Route path="/callback" element={<Callback/>}/>
                            {/*<Route path="/calendar" element={<CalendarAdmin/>}/>*/}
                            <Route path="/calendar" element={<PrivateRoute element={getCalendarComponent()}/>}/>
                            <Route path="/profile" element={<PrivateRoute element={Profile}/>}/>
                            {/*<Route path="/centers" element={<CenterList/>}/>*/}
                            <Route path="/centers" element={<PrivateRoute element={getCenterComponent()}/>}/>
                            <Route path="/services" element={<PrivateRoute element={ServicesMain}/>}/>
                            <Route path="/trainers" element={<PrivateRoute element={TrainerList}/>}/>
                            <Route path="/clients" element={<PrivateRoute element={ClientList}/>}/>
                            <Route path="/statistics" element={<div>En construcción -> {selectedPage}</div>}/>
                            <Route path="/billing" element={<div>En construcción -> {selectedPage}</div>}/>
                            <Route path="*" element={<div>Página no encontrada</div>}/>
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
