import {jwtDecode} from "jwt-decode";
import {getUserById} from "../services/ApiUsers";

const authService = {
    getAccessToken: async (code) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_PUBLIC_URL}/oauth2/token`, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    code
                })
            });
            if (!response.ok) {
                console.error('Network response was not ok');
            }
            const data = await response.json();

            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            localStorage.setItem('expires_in', data.expires_in);

            const decodedToken = jwtDecode(data.access_token);
            let roles = decodedToken.roles;
            let value = Array.isArray(roles) ? roles : roles.split(',');
            let value1 = JSON.stringify(value);

            localStorage.setItem('roles', value1);
            localStorage.setItem('name', decodedToken.name);
            localStorage.setItem('surname', decodedToken.surname);
            localStorage.setItem('username', decodedToken.username);
            localStorage.setItem('userId', decodedToken.user_id);
            localStorage.setItem('centerId', decodedToken.center_id);

            let userById = getUserById(decodedToken.user_id);
            userById
                .then((data) => {
                    console.log(data)
                    localStorage.setItem('center', data.center);
                    localStorage.setItem('user', data);
                })
                .catch((error) => console.log('Error fetching user', error));

            return data;
        } catch (error) {
            console.error('--AuthService: Error fetching access token:', error);
        }
    },
};

export default authService;