import React from 'react';
import {useNavigate} from 'react-router-dom';

const LogoutButton = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            const revoke = await fetch(`${process.env.REACT_APP_SERVER_PUBLIC_URL}/oauth2/revoke`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                }
            });

            const response = await fetch(`${process.env.REACT_APP_SECURITY_SERVER_URL}/logout`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                }
            });

            console.log('PRE Logout successful');
            if (response.ok) {
                // Clear local storage
                console.log('Logout successful');
                localStorage.removeItem('access_token');
                localStorage.removeItem('roles');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('name');
                localStorage.removeItem('surname');
                localStorage.removeItem('username');
                document.cookie = 'JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                document.cookie = "remember-me=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

                // Redirect to login page
                navigate('/login');
            } else {
                console.error('Logout failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    return (
        <a href="#" onClick={handleLogout} style={{textDecoration: 'none', color: '#202020'}}>
            Logout
        </a>
    );
};

export default LogoutButton;