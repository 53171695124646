import {Card, Col, Row} from "react-bootstrap";
import optsIcon from "../../assets/puntitos.svg";
import plusIcon from "../../assets/añadir.svg";
import React, {useEffect, useState} from "react";
import '../CardListStyle.css';
import novaIcon from "../../assets/favicon.svg";
import getCenters from "../center/GetCenters";
import {getAllClients} from "../../services/ApiUsers";
import './Client.css';
import ClientModal from "./ClientModal";

const ClientList = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const {centers, selectedCenter, setSelectedCenter} = getCenters();
    const [clients, setClients] = useState([]);
    const [show, setShow] = useState(false);
    const [selectedClient, setSelectedClient] = useState(false);
    const [mode, setMode] = useState('create');

    const handleIconClick = () => {
        setMenuVisible(!menuVisible);
    };

    const onEditClick = (client) => {
        setShow(true);
        setSelectedClient(client);
        setMode('edit');
        console.log("Edit client", client);
    }

    const handleClose = () => {
        setSelectedClient(null);
        setShow(false);
        setMode('create');
        fetchClients();
    }

    const fetchClients = () => {
        let params = {
            centerId: selectedCenter ? selectedCenter.id : null
        }
        getAllClients(params)
            .then((data) => {
                setClients(data)
            })
            .catch((error) => console.log('Error fetching clients', error));
    }

    useEffect(() => {
        if (selectedCenter) {
            fetchClients();
        }
    }, [selectedCenter]);

    return (
        <div className="services-main-container">
            <div className="centers-container">
                {centers.slice(0).map((center) => (
                    <div
                        key={center.id}
                        onClick={() => setSelectedCenter(center)}
                        className={`center-item ${selectedCenter === center ? 'selected' : ''}`}
                    >
                        {center.name}
                    </div>
                ))}
                <div id="addNew" className="text-right" style={{position: 'absolute', right: '50px'}}>
                    <img src={plusIcon} alt="Add" className="opts-icon" style={{width: '30px', height: '30px'}}
                         onClick={() => setShow(true)}/>
                </div>
            </div>

            <div className="client-list-container">
                {/*<div className="row">*/}
                <Row className="client-list-row">
                    {clients && clients.map((client) => (
                        // <div className="col-12 col-md-6 mb-4" key={client.id}>
                        <Col key={client.id} xs={12} sm={6} md={4} lg={3}>
                            <Card className="text-center mb-4 card-custom" onClick={() => onEditClick(client)}>
                                <div className="icon-container" onClick={() => handleIconClick(client)}>
                                    <img src={optsIcon} alt="Add" className="opts-icon"/>
                                </div>
                                <Card.Img variant="top" src={client.image == null ? novaIcon : client.image}
                                          alt={client.name} className="card-img-top"/>
                                <Card.Body>
                                    <Card.Title>{client.name} {client.surname}</Card.Title>
                                    {/*<Card.Subtitle className="mb-2 text-muted">{service.center.tlf}<br />{service.center.email}</Card.Subtitle>*/}
                                    <Card.Text>
                                        {client.tlf}<br/>
                                        {client.email}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            {/*</div>*/}
                        </Col>
                    ))}
                    {/*</div>*/}
                </Row>
            </div>
            <ClientModal show={show} client={selectedClient} onClose={handleClose} mode={mode} centers={centers}
                         fetchClients={fetchClients}/>
        </div>
    );
};

export default ClientList;
