import React, {useEffect, useState} from "react";
import {Calendar, momentLocalizer, Views} from "react-big-calendar";
import moment from 'moment';
import 'moment/locale/es';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarAdmin.css';
import ClassForm from "./ClassForm";
import getCenters from "../center/GetCenters";
import {getEvents} from "../../services/ApiClassSessions";
import {FaCircleChevronLeft, FaCircleChevronRight} from "react-icons/fa6";

const formats = {
    dayFormat: (date, culture, localizer) => moment(date).format('dd D'),
    weekdayFormat: (date, culture, localizer) => moment(date).format('dd D'),
    dateFormat: 'D',
    // monthHeaderFormat: 'MMMM YYYY',
    monthHeaderFormat: (date, culture, localizer) => moment(date).format('MMMM YYYY'),
    weekHeaderFormat: (date, culture, localizer) => moment(date).format('MMMM YYYY'),
    timeGutterFormat: (date) => moment(date).format(' HH:mm '),
};

const messages = {
    allDay: 'Todo el día',
    previous: '<<',
    next: '>>',
    today: 'Hoy',
    month: 'Mes',
    week: 'Semana',
    day: 'Día',
    agenda: 'Agenda',
    date: 'Fecha',
    time: 'Hora',
    event: 'Evento',
    noEventsInRange: 'No hay eventos en este rango de fechas',
    monday: 'Lunes',
    mon: 'Lun',
    october: 'Octubre',
    showMore: (total) => `+ Ver más (${total})`,
};

const blockedDays = [
    new Date(2024, 10, 25),
    new Date(2024, 10, 31),
];
const CalendarAdmin = () => {
    const {centers, selectedCenter, setSelectedCenter} = getCenters();
    const [view, setView] = useState('month');
    const [classForm, setClassForm] = useState(false);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [mode, setMode] = useState('create');
    const [eventId, setEventId] = useState(null);
    const [events, setEvents] = useState([]);

    const fetchEvents = async () => {
        console.log('Fetching events...', selectedCenter);
        try {
            const eventsData = await getEvents({centerId: selectedCenter.id});
            const formattedEvents = eventsData.map(event => ({
                ...event,
                start: new Date(event.start), //moment(event.start).format('YYYY-MM-DDTHH:mm:ss'),
                end: new Date(event.end) //moment(event.end).format('YYYY-MM-DDTHH:mm:ss')
            }));
            setEvents(formattedEvents);
            console.log('Events:', formattedEvents);
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    };

    useEffect(() => {
        console.log('Selected center:', selectedCenter);
        if (selectedCenter !== null) {
            fetchEvents();
        }
    }, [selectedCenter]);

    const handleSelectSlot = ({start, end}) => {
        setMode('create');
        const selectedDate = moment(start).startOf('day');
        if (blockedDays.find(blockedDate => moment(blockedDate).isSame(selectedDate))) {
            console.log('Este día está bloqueado.');
        } else {
            setStart(start);
            setEnd(end);
            setClassForm(true);
        }
    };

    const handleSelectEvent = (event) => {
        setMode('edit');
        setEventId(event.id);
        console.log('Selected event:', event);
        setClassForm(true);
    };

    const eventStyleGetter = (event, isSelected) => {
        const backgroundColor = event.color || '#65b4bd';
        const style = {
            backgroundColor,
            borderRadius: '5px',
            opacity: 1,
            color: 'white',
            border: '0px',
            display: 'block',
        };
        if (isSelected) {
            style.border = 'none'; // Ejemplo de estilo para el evento seleccionado
        }
        return {
            style,
        };
    };

    const dayStyleGetter = (date) => {
        const selectedDate = moment(date).startOf('day');
        if (blockedDays.some(blockedDate => moment(blockedDate).isSame(selectedDate))) {
            return {
                className: 'blocked',
            };
        }
        return {};
    };

    const handleCloseModal = () => {
        setClassForm(false);
        setMode('create');
        fetchEvents();
    };

    moment.updateLocale('es', {
        week: {
            dow: 1, // Monday is the first day of the week
        },
    });
    moment.updateLocale('es', {
        weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        weekdaysShort: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        months: [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ],
        monthsShort: [
            'ene', 'feb', 'mar', 'abr', 'may', 'jun',
            'jul', 'ago', 'sep', 'oct', 'nov', 'dic'
        ],
        week: {
            dow: 1, // Establecer lunes como el primer día
        }
    });


    const CustomToolbar = (props) => {
        const {onNavigate, onView, label} = props;

        const startOfWeek = moment(props.date).startOf('week'); // Ajustar según el primer día de la semana (default es domingo)
        const endOfWeek = moment(props.date).endOf('week');

        // Formatear los días y el mes
        const startDay = startOfWeek.format('dddd DD'); // Ejemplo: "Lunes 04"
        const endDay = endOfWeek.format('dddd DD'); // Ejemplo: "Domingo 10"
        const month = startOfWeek.format('MMMM'); // Ejemplo: "Noviembre"
        const year = startOfWeek.format('YYYY'); // Ejemplo: "2024"
        let dateLabel = `${startDay} - ${endDay} de ${month} de ${year}`;
        if (props.view === Views.MONTH) {
            dateLabel = `${month} ${year}`
        }

        return (
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                margin: '0.8rem 0'
            }}>

                <div style={{display: 'flex', gap: '10px'}}>
                    <FaCircleChevronLeft onClick={() => props.onNavigate('PREV')} cursor={'pointer'}
                                         style={{width: '30px', height: '1.5rem', color: '#202020'}}/>
                    <FaCircleChevronRight onClick={() => props.onNavigate('NEXT')} cursor={'pointer'}
                                          style={{width: '30px', height: '1.5rem', color: '#202020'}}/>
                </div>

                <span style={{fontWeight: 'bold'}}>
                    {dateLabel}
                </span>
                <div style={{display: 'flex', gap: '10px'}}>
                    <button className={`center-item ${props.view === Views.MONTH ? 'selected' : ''}`}
                            onClick={() => props.onView(Views.MONTH)}>Mes
                    </button>
                    <button className={`center-item ${props.view === Views.WEEK ? 'selected' : ''}`}
                            onClick={() => props.onView(Views.WEEK)}>Semana
                    </button>
                    <button className={`center-item ${props.view === Views.AGENDA ? 'selected' : ''}`}
                            onClick={() => props.onView(Views.AGENDA)}>Día
                    </button>


                </div>

            </div>
        );
    }


    const localizer = momentLocalizer(moment);
    return (
        <div>
            <div className="centers-container">
                {centers.slice(0).map((center) => (
                    <div
                        key={center.id}
                        onClick={() => setSelectedCenter(center)}
                        className={`center-item ${selectedCenter === center ? 'selected' : ''}`}
                    >
                        {center.name}
                    </div>
                ))}
            </div>

            <Calendar
                localizer={localizer}
                events={events}
                min={new Date(1970, 1, 1, 7, 0, 0)}
                max={new Date(1970, 1, 1, 21, 0, 0)}
                selectable
                startAccessor="start"
                endAccessor="end"
                messages={messages}
                views={['month', 'week']}
                defaultView={'week'}
                onSelectSlot={handleSelectSlot}
                onSelectEvent={handleSelectEvent}
                style={{height: 600}}
                eventPropGetter={eventStyleGetter}
                formats={formats}
                dayPropGetter={dayStyleGetter}
                components={{
                    toolbar: CustomToolbar,
                }}
            />
            <ClassForm
                selectedCenter={selectedCenter}
                start={start}
                end={end}
                handleClose={handleCloseModal}
                show={classForm}
                mode={mode}
                eventId={eventId}
                setEventId={setEventId}
            />
        </div>
    );
};

export default CalendarAdmin;