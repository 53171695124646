import apiFetch from "./api";

const apiUrl = `${process.env.REACT_APP_SERVER_URL}/user`;

const getAllTrainers = async (params) => {
    // const queryString = new URLSearchParams(params).toString();
    const queryString = Object.keys(params).length ? "?" + new URLSearchParams(params).toString() : "";
    const response = await apiFetch(`${apiUrl}/trainers${queryString}`, {
        method: 'GET',
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const getAllClients = async (params) => {
    // const queryString = new URLSearchParams(params).toString();
    const queryString = Object.keys(params).length ? "?" + new URLSearchParams(params).toString() : "";
    const response = await apiFetch(`${apiUrl}/clients${queryString}`, {
        method: 'GET',
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const getUserById = async (id) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {method: 'GET', redirect: "manual", credentials: 'include'});
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
}

const getTrainerById = async (id) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {method: 'GET', redirect: "manual", credentials: 'include'});
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const createTrainer = async (trainer) => {
    const response = await apiFetch(apiUrl, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(trainer),
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const updateTrainer = async (id, trainer) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(trainer),
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const deleteTrainer = async (id) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {
        method: 'DELETE',
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};
const updateUserAvailable = async (id, available) => {
    const response = await apiFetch(`${apiUrl}/${id}/available/${available}`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
};

const updateUserPayment = async (id, payment) => {
    const response = await apiFetch(`${apiUrl}/${id}/payment`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(payment),
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
};

const updateUser = async (id, user) => {
    const response = await apiFetch(`${apiUrl}/${id}/admin`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(user),
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const addGoal = async (id, goal) => {
    const response = await apiFetch(`${apiUrl}/${id}/goal`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(goal),
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
}

const deleteGoal = async (id) => {
    const response = await apiFetch(`${apiUrl}/goal/${id}`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
}

const addInjury = async (id, injury) => {
    const response = await apiFetch(`${apiUrl}/${id}/injury`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(injury),
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
}

const deleteInjury = async (id) => {
    const response = await apiFetch(`${apiUrl}/injury/${id}`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
}

export {
    getAllTrainers, getTrainerById, createTrainer, updateTrainer, deleteTrainer, getAllClients, getUserById, updateUser,
    addGoal, deleteGoal, addInjury, deleteInjury, updateUserAvailable, updateUserPayment
};