import React, {useEffect, useState} from "react";
import '../../FormStyle.css';
import {Button, Col, Form, InputGroup, Modal, Row} from "react-bootstrap";
import novaIcon from "../../../assets/favicon.svg";
import {createService, updateService} from "../../../services/ApiServices";
import {getAllTrainers} from "../../../services/ApiUsers";


const ServiceForm = ({show, handleClose, mode, data, selectedCenter, onServiceChange}) => {
    const [name, setName] = useState("");
    const [duration, setDuration] = useState(0);
    const [trainer, setTrainer] = useState("");
    const [trainers, setTrainers] = useState([]);
    const [color, setColor] = useState("");
    const [capacity, setCapacity] = useState(1);
    const [available, setAvailable] = useState(true);
    const [order, setOrder] = useState(1);
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(data?.image || novaIcon);
    const [type, setType] = useState("individual");

    useEffect(() => {
        if (mode === "edit" && data) {
            setName(data.name);
            setDuration(data.duration);
            setTrainer(data.trainer.id);
            setColor(data.color);
            setCapacity(data.capacity);
            setAvailable(data.available);
            setImage(data.image)
            setPreview(data.image);
            setType(data.capacity === 1 ? 'individual' : 'grupal');
        } else {
            setName("");
            setDuration(0);
            setTrainer("");
            setColor("");
            setCapacity(1);
            setAvailable(true);
            setImage(null);
            setPreview(null);
            setType("individual");
        }
    }, [mode, data]);

    useEffect(() => {
        if (selectedCenter) {
            getAllTrainers({centerId: selectedCenter.id})
                .then(data => setTrainers(data))
                .catch(error => console.error('Error fetching trainers:', error));
        }
    }, [show]);

    const handleSubmit = (e) => {
        const selectedTrainer = trainers.find(t => t.id === Number(trainer));
        e.preventDefault();
        type === 'individual' ? setCapacity(1) : setCapacity(capacity);

        const dataForm = {
            center: selectedCenter,
            name,
            trainer: selectedTrainer,
            duration,
            color,
            capacity,
            available,
            order,
            image: preview
        };
        if (mode === "edit") {
            updateService(data.id, dataForm).then(() => onServiceChange(selectedCenter.id));
        } else {
            createService(dataForm).then(() => onServiceChange(selectedCenter.id));
        }

        handleClose();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
        console.log("File: ", file);

        loadImagePreview(file);
        console.log("Preview ", preview);
    };

    const loadImagePreview = (image) => {
        // Vista previa de la imagen
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (image) {
            reader.readAsDataURL(image);
        }
        return reader.result;
    }

    const handleTypeChange = (e) => {
        const selectedType = e.target.value;
        setType(selectedType);
        if (selectedType === 'individual') {
            setCapacity(1);
        }
        console.log("Capacity: ", capacity);
    };

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="form-modal" size={"lg"} centered>
            <Modal.Header closeButton style={{border: "none", padding: "0.5rem"}}>
                {/*<Modal.Title>Servicio</Modal.Title>*/}
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group controlId="formImagen">
                                <Form.Label>Imagen del Curso</Form.Label>
                                <div className="mt-3 image-div">
                                    <img src={image ? preview : novaIcon} alt="Vista previa" style={{
                                        width: '100px',
                                        height: '100px',
                                        borderRadius: '50%',
                                        objectFit: 'cover'
                                    }}/>
                                </div>
                                <Form.Control className={"sm"}
                                    type="file"
                                    onChange={(e => handleFileChange(e))}
                                    accept="image/*"
                                />
                            </Form.Group>

                            {/*)}*/}
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="formName">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type="text" placeholder="Ingresa el nombre" value={name}
                                              onChange={(e) => setName(e.target.value)} required/>
                            </Form.Group>
                            <Form.Group controlId="formDuration">
                                <Form.Label>Duración (minutos)</Form.Label>
                                <InputGroup>
                                    <Form.Control type="number" placeholder="(min)" value={duration}
                                                  onChange={(e) => setDuration(e.target.value)} required/>
                                </InputGroup>
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="formTrainer">
                                <Form.Label>Entrenador</Form.Label>
                                <Form.Control as="select" value={trainer} onChange={(e) => setTrainer(e.target.value)}
                                              required>
                                    <option value="">Selecciona un entrenador</option>
                                    {trainers.map(trainer => (
                                        <option key={trainer.id} value={trainer.id}>{trainer.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="formColor">
                                <Form.Label>Color</Form.Label>
                                <Form.Control
                                    type="color"
                                    value={color}
                                    onChange={(e) => setColor(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="formTipo">
                                <Form.Label>Nº de clientes</Form.Label>
                                <Form.Check type="radio" label="Individual" name="tipoCurso" value="individual"

                                            checked={type === 'individual'} onChange={handleTypeChange}/>
                                <Form.Check type="radio" label="Grupal" name="tipoCurso" value="grupal"

                                            checked={type === 'grupal'} onChange={handleTypeChange}/>
                            </Form.Group>
                        </Col>

                        {/* Input de capacidad, se muestra solo si el tipo es "grupal" */}
                        {type === 'grupal' && (
                            <Col md={6}>
                                <Form.Group controlId="formCapacity">
                                    <Form.Label>Capacidad</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={capacity}
                                        onChange={(e) => setCapacity(e.target.value)}
                                        placeholder="Capacidad"
                                    />
                                </Form.Group>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="formOrder">
                                <Form.Label>Orden</Form.Label>
                                <Form.Control type="number" placeholder="1" value={order}
                                              onChange={(e) => setOrder(e.target.value)} required/>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="formAvailable">
                                <Form.Label>Mostrar en la App</Form.Label>
                                <Form.Check
                                    className={`custom-switch ${available ? 'checked' : 'unchecked'} shadow-none`}
                                    type="switch"
                                    label={available ? "Si" : "No"}
                                    checked={available}
                                    onChange={(e) => setAvailable(e.target.checked)} required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="cancel-btn" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button variant="primary" className="save-btn" type="submit" onClick={e => handleSubmit(e)}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    )
        ;
};

export default ServiceForm;