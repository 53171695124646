import {Col, Form, Modal, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {cancelSession, getSessionById} from "../../services/ApiClassSessions";

const ReservationForm = ({eventId, show, handleClose}) => {
    const [initDate, setInitDate] = useState("");
    const [initTime, setInitTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [serviceName, setServiceName] = useState("");

    useEffect(() => {
        console.log("Fetching event: ", eventId);
        getSessionById(eventId)
            .then(data => {
                console.log("Data: ", data);
                let initDateV = new Date(data.initTime);
                setInitDate(' ' + initDateV.toISOString().split('T')[0]);
                setInitTime(' ' + initDateV.toTimeString().split(' ')[0].substring(0, 5));

                let endDate = new Date(data.endTime);
                setEndTime(' ' + endDate.toTimeString().split(' ')[0].substring(0, 5));

                setServiceName(' ' + data.service.name);
            })
            .catch(error => console.error('Error fetching event:', error));
    }, []);

    const handleCancelReservation = async () => {
        console.log("Cancel reservation");
        let userId = localStorage.getItem('userId');
        await cancelSession(userId, eventId)
            .then(() => {
                console.log("Session cancelled");
                handleClose();
            });
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Datos de la reserva</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Row>
                    <Col md={12}>
                        <Form.Label>Servicio:</Form.Label>
                        {serviceName}
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Label style={{marginLeft: '0.2rem'}}>Día: </Form.Label>
                        {initDate}
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Label>Hora: </Form.Label>
                        {initTime} - {endTime}
                    </Col>
                </Row>
                <Form>
                    <p onClick={(e) => handleCancelReservation()}>x Eliminar Reserva</p>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default ReservationForm;