import {useEffect, useRef} from 'react';

function useTokenRefresher() {
    const lastActivityRef = useRef(Date.now());

    const refreshToken = async () => {
        const refreshToken = localStorage.getItem('refresh_token');
        console.log('Refrescando token...', refreshToken);
        if (refreshToken !== null) {
            try {
                const response = await fetch('/auth/refresh', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({refreshToken}),
                });
                if (response.ok) {
                    const {accessToken, refreshToken: newRefreshToken, expiresIn} = await response.json();

                    // Almacenar el nuevo access token y, si está presente, también el nuevo refresh token
                    localStorage.setItem('access_token', accessToken);
                    if (newRefreshToken) {
                        localStorage.setItem('refresh_token', newRefreshToken);
                    }
                    localStorage.setItem('expires_in', expiresIn);
                } else {
                    console.error('Error: no se pudo refrescar el token');
                }
            } catch (error) {
                console.error('Error al refrescar el token', error);
            }
        } else {
            console.log('No hay token de refresco almacenado');
        }
    };

    const checkAndRefreshToken = () => {
        const now = Date.now();
        console.log('Revisando token...', now);
        console.log('Última actividad:', lastActivityRef.current);
        const fiveMinutes = 4 * 60 * 1000;

        // Comprobamos si hubo actividad en los últimos 5 minutos
        if (now - lastActivityRef.current < fiveMinutes) {
            refreshToken();
        } else {
            console.warn('Token ha caducado debido a inactividad');
            // Opcional: puedes redirigir a la pantalla de login o manejar la caducidad aquí
        }
    };

    useEffect(() => {
        // Configura el intervalo para revisar cada 5 minutos sin reiniciarse
        const intervalId = setInterval(checkAndRefreshToken, 4 * 60 * 1000); // Cada 5 minutos

        // Actualiza el último timestamp de actividad cada vez que el usuario interactúe
        const updateActivity = () => {
            lastActivityRef.current = Date.now();
        };

        // Escuchar eventos de actividad del usuario
        window.addEventListener('mousemove', updateActivity);
        window.addEventListener('keydown', updateActivity);

        return () => {
            clearInterval(intervalId);
            window.removeEventListener('mousemove', updateActivity);
            window.removeEventListener('keydown', updateActivity);
        };
    }, []);
}

export default useTokenRefresher;
