import {Button, Card, Col, Form, ListGroup, Row} from "react-bootstrap";
import "../CardListStyle.css";
import React, {useEffect, useState} from "react";
import novaIcon from "../../assets/favicon.svg";
import {getAllBonos} from "../../services/ApiBonos";
import {updateUserPayment} from "../../services/ApiUsers";

const Payments = ({clientId, client, fetchClients}) => {

    const [selectedBonoId, setSelectedBonoId] = useState(null);
    const [bono, setBono] = useState(null);
    const [bonos, setBonos] = useState([]);
    const [payments, setPayments] = useState([
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pendiente'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'},
        {bono: 'Bono 2 dias completos M/J', date: '01/01/2021', method: 'Transferencia', status: 'Pagado'}
    ]);

    useEffect(() => {
        console.log("Client----: ", client);
        setBono(client.bono);
        getAllBonos({centerId: client.center.id}).then((data) => {
            setBonos(data)
        });
        setSelectedBonoId(client.bono?.id);
        console.log("bonoooo", bono)
    }, []);

    useEffect(() => {
        console.log("Bono update: ", selectedBonoId);
        console.log("Bonos: ", bonos);
        let asd = bonos.find(b => b.id === parseInt(selectedBonoId, 10));
        // let asd = bonos.find(b => b.id === selectedBonoId);
        console.log("Bono update: ", asd);
        setBono(asd);
    }, [selectedBonoId]);

    const onSubmit = (e) => {
        e.preventDefault();
        let payment = {
            bonoId: selectedBonoId,
            paymentMethodId: 1
        };
        updateUserPayment(client.id, payment).then(fetchClients());
    }

    return (
        <>
            <br/>
            <Row md={6} className="justify-content-md-center">
                <Col style={{width: '30%'}}>
                    <Row className="justify-content-md-center">
                        <Form.Group controlId="formBono" style={{width: '16rem'}}>
                            <Form.Label>Bonos disponibles</Form.Label>
                            <Form.Control as="select" value={bono}
                                          onChange={(e) => setSelectedBonoId(e.target.value)}>
                                <option value="">Selecciona un bono</option>
                                {bonos.map(bono => (
                                    <option key={bono.id} value={bono.id}>{bono.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Card className="card-custom text-center"
                              style={{
                                  width: '15rem',
                                  height: '12rem',
                                  minWidth: '15rem',
                                  minHeight: '10rem',
                                  marginRight: '0'
                              }}>
                            <Card.Body>
                                <Card.Img variant="top" src={bono?.image == null ? novaIcon : bono.image}
                                          alt={'img'} className="card-img-top" style={{width: '5rem', height: '5rem'}}/>
                                <Card.Title>{bono !== null ? bono?.name : " "}</Card.Title>
                                <Card.Text>
                                    {bono === null ? "El usuario no tiene ningún bono asignado." :
                                        [
                                            bono?.center?.address
                                        ]}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                    <br/>
                    <Row className="justify-content-md-center">
                        <Form.Group controlId="formPaymentMethods" style={{width: '16rem'}}>
                            <Form.Label>Métodos de pago</Form.Label>
                            <Form.Control as="select" value={bono}
                                          onChange={(e) => setSelectedBonoId(e.target.value)}>
                                <option value="">Selecciona un método</option>
                                {bonos.map(bono => (
                                    <option key={bono.id} value={bono.id}>{bono.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Card className="card-custom text-center"
                              style={{
                                  width: '15rem',
                                  height: '7rem',
                                  minWidth: '15rem',
                                  minHeight: '7rem',
                                  marginRight: '0'
                              }}>
                            <Card.Body>
                                {/*<Card.Title>{bono !== null ? bono?.name : " "}</Card.Title>*/}
                                <Card.Text>
                                    Cuenta bancaria: <br/>
                                    **** **** **** **** **** 1234
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
                <Col style={{width: '70%'}}>
                    <Row>
                        <Form.Group controlId="formPayments">
                            <Form.Label>Historial de pagos</Form.Label>

                            <ListGroup as="ol" style={{maxHeight: '25rem', overflowY: 'auto'}}>
                                {payments.map((payment) => (

                                    <ListGroup.Item as="li" key={payment.id}>
                                        <Row md={3} className="justify-content-md-center">
                                            <Col style={{width: '40%'}}>
                                                {payment.bono}
                                            </Col>
                                            <Col style={{width: '20%'}}>
                                                {payment.method}
                                            </Col>
                                            <Col style={{width: '20%'}}>
                                                {payment.date}
                                            </Col>

                                            <Col style={{
                                                width: '20%',
                                                color: payment.status === 'Pagado' ? 'green' : payment.status === 'Pendiente' ? 'orange' : 'black'
                                            }}>
                                                {payment.status}
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Button variant="primary" className="save-btn float-xl-end" type="submit"
                    style={{position: 'absolute', bottom: '2rem', right: '2rem'}}
                    onClick={e => onSubmit(e)}>
                Guardar
            </Button>
        </>
    )
}
export default Payments;