import React, {useEffect, useState} from "react";
import {Button, Col, Dropdown, DropdownButton, Form, Modal, Row} from "react-bootstrap";
import registerIcon from "../../assets/registro-usuario-32.svg";
import closeIcon from "../../assets/cerrar-hover-giro90º.svg";
import {getAllClients} from "../../services/ApiUsers";
import '../FormStyle.css';
import {getAllServices} from "../../services/ApiServices";
import {
    deleteSerie,
    deleteSession,
    getSessionById,
    postSession,
    putSerie,
    putSession
} from "../../services/ApiClassSessions";

const frequencies = [
    {name: '1 día', code: 'DAILY'}, {name: 'Semanal', code: 'WEEKLY'}, {name: 'Mensual', code: 'MONTHLY'}];
const allDays = [
    {name: 'Lunes', code: 'MONDAY'}, {name: 'Martes', code: 'TUESDAY'}, {name: 'Miércoles', code: 'WEDNESDAY'},
    {name: 'Jueves', code: 'THURSDAY'}, {name: 'Viernes', code: 'FRIDAY'}, {name: 'Sábado', code: 'SATURDAY'},
    {name: 'Domingo', code: 'SUNDAY'}];

const ClassForm = ({selectedCenter, start, end, handleClose, show, mode, eventId, setEventId}) => {
    const [trainer, setTrainer] = useState({});
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState(""); // Initialize to an empty string
    const [frequency, setFrequency] = useState("MONTHLY");
    const [amount, setAmount] = useState(1);
    const [initDate, setInitDate] = useState("");
    const [initTime, setInitTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [weekDays, setWeekDays] = useState([]);
    const [capacity, setCapacity] = useState(1);
    const [availableClients, setAvailableClients] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [waitingList, setWaitingList] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [wlSelectedClientId, setWlSelectedClientId] = useState(null);
    const [color, setColor] = useState("");
    const [order, setOrder] = useState(1);

    useEffect(() => {
        console.log('Mode:', mode);
        if (mode === 'edit') {
            // Fetch the event data
            getSessionById(eventId)
                .then(data => {
                    console.log("Data: ", data);
                    let initDateV = new Date(data.initTime);
                    setInitDate(initDateV.toISOString().split('T')[0]);
                    setInitTime(initDateV.toTimeString().split(' ')[0].substring(0, 5));

                    let endDate = new Date(data.endTime);
                    setEndTime(endDate.toTimeString().split(' ')[0].substring(0, 5));

                    setFrequency(data.frequency);
                    setAmount(data.amount);
                    setWeekDays(data.weekDays);
                    setColor(data.service.color);
                    setCapacity(data.service.capacity);
                    setSelectedService(data.service.id);
                    setTrainer(data.trainer?.id === null ? data.service.trainer : data.trainer);
                    setSelectedClients(data.attendees);
                    setWaitingList(data.waitlist);
                    setOrder(waitingList.length);
                })
                .catch(error => console.error('Error fetching event:', error));
        } else {
            if (start) {
                setInitDate(start.toISOString().split('T')[0]);
                const startHours = start.getHours().toString().padStart(2, '0');
                const startMinutes = start.getMinutes().toString().padStart(2, '0');
                setInitTime(`${startHours}:${startMinutes}`);
            }
            if (end) {
                const endHours = end.getHours().toString().padStart(2, '0');
                const endMinutes = end.getMinutes().toString().padStart(2, '0');
                setEndTime(`${endHours}:${endMinutes}`);
            }
        }
        if (selectedCenter) {
            getAllServices({
                centerId: selectedCenter ? selectedCenter.id : null,
                available: true
            })
                .then(data => setServices(data))
                .catch(error => console.error('Error fetching services:', error));
        }


    }, [selectedCenter, start, end, eventId]);

    useEffect(() => { // Fetch clients cuando cambia el servicio, la frecuencia y los días
        if (mode === 'create') {
            setSelectedClientId("");
            setSelectedClients([]);
        }

        fetchAvailableClients();
        if (frequency === 'DAILY' && weekDays.length > 1) {
            setWeekDays([weekDays[0]]); // Mantén solo el primer día seleccionado
        }
    }, [selectedService, frequency, weekDays, eventId]);

    useEffect(() => {
        fetchAvailableClients();
    }, [selectedCenter, selectedService, weekDays, selectedClients, waitingList]);

    useEffect(() => {
        if (mode === 'edit') {
            let avCli = availableClients.filter(c =>
                (selectedClients && !selectedClients.find(sc => sc.id === c.id)) &&
                (waitingList && !waitingList.find(wl => wl.user.id === c.id))
            );
            setAvailableClients(avCli);
        }
        console.log("Waiting list:--", waitingList); // Este se activará cuando waitingList cambie
        console.log("selectedClients:--", selectedClients); // Este se activará cuando selectedClients cambie
        console.log("Available clients:--", availableClients); // Este se activará cuando selectedClients cambie
    }, [selectedClients, waitingList, order]);

    const fetchAvailableClients = () => {
        console.log("Fetching clients...");
        if (selectedCenter !== null) {
            const params = {
                centerId: selectedCenter.id,
                available: true,
                service: selectedService,
                weekDays: weekDays.map(day => day.code)
            };
            getAllClients(params)
                .then(data => {
                    let avCli = data.filter(c =>
                        (selectedClients && !selectedClients.find(sc => sc.id === c.id)) &&
                        (waitingList && !waitingList.find(wl => wl.user.id === c.id))
                    );
                    setAvailableClients(avCli);
                })
                .catch(error => console.error('Error fetching clients:', error));

        }
    }

    const handleCloseModal = () => {
        setSelectedService("");
        setTrainer(null);
        setFrequency("MONTHLY");
        setAmount(1);
        setWeekDays([]);
        setSelectedClients([])
        setEventId(null);
        handleClose();
    }

    const handleDeleteSession = () => {
        // Logic to delete session
        console.log("Session deleted");
        deleteSession(eventId);
        handleCloseModal();
    };

    const handleDeleteSeries = () => {
        // Logic to delete series
        console.log("Series deleted");
        deleteSerie(eventId);
        handleCloseModal();
    };

    const handleUpdateSession = () => {
        // Logic to delete session
        console.log("Session updated");
        let event = {
            centerId: selectedCenter,
            service: services.find(c => c.id === parseInt(selectedService)),
            trainer: trainer,
            initDate: initDate,
            initTime: `${initDate}T${initTime}:00`,
            endTime: `${initDate}T${endTime}:00`,
            frequency: frequency,
            amount: amount,
            weekDays: weekDays,
            attendees: selectedClients,
            id: eventId,
            waitlist: waitingList
        }
        putSession(eventId, event);
        handleCloseModal();
    };

    const handleUpdateSeries = () => {
        // Logic to delete series
        console.log("Series updated");
        let event = {
            centerId: selectedCenter,
            service: services.find(c => c.id === parseInt(selectedService)),
            trainer: trainer,
            initDate: initDate,
            initTime: `${initDate}T${initTime}:00`,
            endTime: `${initDate}T${endTime}:00`,
            frequency: frequency,
            amount: amount,
            weekDays: weekDays,
            attendees: selectedClients,
            waitlist: waitingList
        }
        putSerie(eventId, event);
        handleCloseModal();
    };

    const addSelectedDay = (day) => {
        let days = [...weekDays];
        if (frequency === 'DAILY') {
            days = [day]; // Replace the current day with the new one
        } else {
            if (days.find(d => d.code === day.code)) {
                days = days.filter(d => d !== day);
            } else {
                days.push(day);
            }
        }
        setWeekDays(days);
    }

    const addSelectedClient = (clientId) => {
        let client = availableClients.find(c => c.id === parseInt(clientId));
        console.log("Client: ", client);
        let clients = selectedClients;
        if (clients && client && clients.find(c => c.id === client.id)) {
            availableClients.push(client);
            clients = clients.filter(c => c.id !== client.id);
        } else {
            setAvailableClients(availableClients.filter(c => c.id !== client.id));
            clients.push(client);
        }
        setSelectedClients([...clients]);
    }

    const addClientToWaitlist = (clientId) => {
        let client = availableClients.find(c => c.id === parseInt(clientId));

        let wlClients = waitingList;
        if (wlClients && wlClients.find(c => c.user.id === client.id)) {
            availableClients.push(client);
            wlClients = wlClients.filter(c => c.user.id !== client.id);
        } else {
            setAvailableClients(availableClients.filter(c => c.id !== client.id));
            wlClients.push({user: client, order: order});
            setOrder(wlClients.length);
        }
        setWaitingList([...wlClients]);
    }

    const handleSelectedService = (e) => {

        setSelectedService(e.target.value);
        let service = services.find(s => s.id === parseInt(e.target.value));
        setCapacity(service?.capacity);
        setTrainer(service?.trainer);
        // setEndTime(`${end.getHours()}:${start.getMinutes()}`);

        let endCalc = start;
        endCalc.setMinutes(start.getMinutes() + service.duration);
        const endHours = endCalc.getHours().toString().padStart(2, '0');
        const endMinutes = endCalc.getMinutes().toString().padStart(2, '0');
        setEndTime(`${endHours}:${endMinutes}`);
        // setEndTime(end);
    }

    const handleSubmit = async (e) => {
        let event = {
            centerId: selectedCenter,
            service: services.find(c => c.id === parseInt(selectedService)),
            trainer: trainer,
            initDate: initDate,
            initTime: `${initDate}T${initTime}:00`,
            endTime: `${initDate}T${endTime}:00`,
            frequency: frequency,
            amount: amount,
            weekDays: weekDays,
            attendees: selectedClients
        }
        console.log("Event: ", event);
        await postSession(event);
        handleCloseModal();
        /*e.preventDefault();
        const response = await apiFetch(`${process.env.REACT_APP_SERVER_URL}/class-sessions`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(event),
            redirect: "manual",
            credentials: 'include'
        });
        if (response.type === 'opaqueredirect') {
            window.location.href = response.url;
            return;
        }
        return await response.json();
        */

    }

    return (
        <>
            <Modal show={show} onHide={handleClose} dialogClassName="form-modal" size={"lg"} centered>
                <Modal.Header closeButton style={{border: "none", padding: "0.5rem"}}></Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formServices">
                                    <Form.Label>Servicio</Form.Label>
                                    <Form.Control as="select" value={selectedService || ""} // Use empty string if null
                                                  onChange={(e) => handleSelectedService(e)}
                                                  required disabled={mode === 'edit'}>
                                        <option value="">Selecciona un servicio</option>
                                        {services.map(service => (
                                            <option key={service.id} value={service.id}>{service.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formTrainer">
                                    <Form.Label>Entrenador</Form.Label>
                                    <Form.Control plaintext readOnly
                                                  value={`${trainer?.name || ''} ${trainer?.surname || ''}`}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formDescription">
                                    <Form.Label>Tipo de clase</Form.Label>
                                    <div className="list-container">
                                        <div className={`item ${capacity === 1 ? 'selected' : ''} disabled`}>
                                            Individual
                                        </div>
                                        <div className={`item ${capacity > 1 ? 'selected' : ''} disabled`}>
                                            Grupal
                                        </div>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formDate">
                                    <Form.Label>Fecha</Form.Label>
                                    {/*<Form.Control plaintext readOnly value={initDate} />*/}
                                    <Form.Control as="input" type="date" value={initDate}
                                                  onChange={(e) => setInitDate(e.target.value)}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formDescription">
                                    <Form.Label>Tipo de clase</Form.Label>
                                    <div className="list-container">
                                        <div className={`item ${capacity === 1 ? 'selected' : ''} disabled`}>
                                            Individual
                                        </div>
                                        <div className={`item ${capacity > 1 ? 'selected' : ''} disabled`}>
                                            Grupal
                                        </div>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formDate">
                                    <Form.Label>Fecha</Form.Label>
                                    {/*<Form.Control plaintext readOnly value={initDate} />*/}
                                    <Form.Control as="input" type="date" value={initDate}
                                                  onChange={(e) => setInitDate(e.target.value)}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formCapacity">
                                    <Form.Label>Capacidad</Form.Label>
                                    {/*<Form.Control plaintext readOnly value={initDate} />*/}
                                    <Form.Control type="text" value={capacity} readOnly/>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formColor">
                                    <Form.Label>Color</Form.Label>
                                    <Form.Control type="color" value={color} readOnly disabled={true}/>
                                    {/*onChange={(e) => setEndTime(e.target.value)} required/>*/}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formInitTime">
                                    <Form.Label>Inicio</Form.Label>
                                    <Form.Control type="time" value={initTime}
                                                  onChange={(e) => setInitTime(e.target.value)} required/>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formEndTime">
                                    <Form.Label>Fin</Form.Label>
                                    <Form.Control type="text" value={endTime} readOnly/>
                                    {/*onChange={(e) => setEndTime(e.target.value)} required/>*/}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group controlId="formDays">
                                    <Form.Label>¿Qué días quieres asignar la clase?</Form.Label>
                                    <div className="list-container">
                                        {allDays.slice(0).map((day) => (
                                            <div
                                                key={day.code}
                                                onClick={() => addSelectedDay(day)}
                                                className={`item ${weekDays.find(d => d.code === day.code) !== undefined ? 'selected' : ''}`}
                                                style={{
                                                    pointerEvents: selectedService?.type === 'DAILY' ? 'none' : 'auto',
                                                    opacity: selectedService?.type === 'DAILY' ? 0.5 : 1
                                                }}
                                            >
                                                {day.name}
                                            </div>
                                        ))}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <Form.Group controlId="formFreq">
                                    <Form.Label>Periodo de tiempo al que aplicar el bono</Form.Label>
                                    <div className="list-container">
                                        {frequencies.slice(0).map((freq) => (
                                            <div
                                                key={freq.code}
                                                onClick={() => setFrequency(freq.code)}
                                                className={`item ${freq.code === frequency ? 'selected' : ''}`}
                                            >
                                                {freq.name}
                                            </div>
                                        ))}
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                {frequency !== 'DAILY' && (
                                    <Form.Group controlId="formAmount">
                                        <Form.Label>Cantidad</Form.Label>
                                        <Form.Control type="number" placeholder="1" value={amount}
                                                      onChange={(e) => setAmount(e.target.value)} required>
                                        </Form.Control>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <Form.Group controlId="formClients">
                                    <Form.Label>Clientes</Form.Label>
                                    <Form.Control as="select" value={selectedClientId || ""}
                                                  onChange={(e) => setSelectedClientId(e.target.value)}>
                                        <option value="">Selecciona un cliente</option>
                                        {availableClients.map(client => (
                                            <option key={client.id} value={client.id}>
                                                {client.name} {client.surname}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="formClientsButton">
                                    <Form.Label></Form.Label>
                                    <br/>
                                    <Button variant="primary" className="nova"
                                            onClick={() => addSelectedClient(selectedClientId)}
                                            disabled={selectedClients.length >= capacity}>
                                        Añadir
                                        <img src={registerIcon} alt="icon" style={{
                                            marginLeft: '8px',
                                            width: '20px',
                                            height: '20px',
                                            filter: 'brightness(0) invert(1)',
                                        }}/>
                                    </Button>
                                    {selectedClients.length >= capacity && (
                                        <div style={{color: 'red', marginTop: '10px'}}>
                                            Capacidad máxima alcanzada
                                        </div>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="list-container">
                                    {selectedClients.map(client => (
                                        <div key={client.id} className="item"
                                             onClick={() => addSelectedClient(client.id)}>
                                            <span>{client.name} {client.surname} </span>
                                            <img src={closeIcon} alt="remove"
                                                 onClick={() => addSelectedClient(client.id)}
                                                 style={{width: '1.2em', cursor: 'pointer'}}/>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                        {mode === 'edit' && (
                            <Row>
                                <Col md={8}>
                                    <Form.Group controlId="formClients">
                                        <Form.Label>Lista de espera</Form.Label>
                                        <Form.Control as="select" value={wlSelectedClientId || ""}
                                                      onChange={(e) => setWlSelectedClientId(e.target.value)}>
                                            <option value="">Selecciona un cliente</option>
                                            {availableClients.map(client => (
                                                <option key={client.id} value={client.id}>
                                                    {client.name} {client.surname}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formClientsButton">
                                        <Form.Label></Form.Label>
                                        <br/>
                                        <Button variant="primary" className="nova"
                                                onClick={() => addClientToWaitlist(wlSelectedClientId)}>
                                            Añadir
                                            <img src={registerIcon} alt="icon" style={{
                                                marginLeft: '8px',
                                                width: '20px',
                                                height: '20px',
                                                filter: 'brightness(0) invert(1)',
                                            }}/>
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                        )}
                        {mode === 'edit' && (
                            <Row>
                                <Col md={12}>
                                    <div className="list-container">
                                        {waitingList && waitingList.map(client => (
                                            <div key={client.user.id} className="item"
                                                 onClick={() => addClientToWaitlist(client.user.id)}>
                                                <span>{client.user.name} {client.user.surname} </span>
                                                <img src={closeIcon} alt="remove"
                                                     onClick={() => addClientToWaitlist(client.user.id)}
                                                     style={{width: '1.2em', cursor: 'pointer'}}/>
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {mode === 'edit' && (
                        /*<Button variant="secondary" className="delete-btn" onClick={handleCloseModal}>
                            Eliminar
                        </Button>*/
                        <DropdownButton id="dropdown-delete-button" title="Eliminar" variant="secondary"
                                        className="delete-btn">
                            <Dropdown.Item onClick={handleDeleteSession}>Eliminar sesión</Dropdown.Item>
                            <Dropdown.Item onClick={handleDeleteSeries}>Eliminar serie</Dropdown.Item>
                        </DropdownButton>
                    )}
                    <Button variant="secondary" className="cancel-btn" onClick={handleCloseModal}>
                        Cancelar
                    </Button>
                    {mode === 'edit' && (
                        <DropdownButton id="dropdown-save-button" title="Guardar" variant="secondary"
                                        className="delete-btn">
                            <Dropdown.Item onClick={handleUpdateSession}>Actualizar sesión</Dropdown.Item>
                            <Dropdown.Item onClick={handleUpdateSeries}>Actualizar serie</Dropdown.Item>
                        </DropdownButton>
                    )}
                    {mode !== 'edit' && (
                        <Button variant="primary" className="save-btn" type="submit" onClick={e => handleSubmit(e)}>
                            Guardar
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

        </>
    )
}
export default ClassForm;