import React from "react";
import {Col, Row} from "react-bootstrap";
import {updateUserAvailable} from "../../services/ApiUsers";

const EnabledProfile = ({clientId, enabled, setEnabled, fetchClients}) => {

    const onDisableClick = () => {
        setEnabled(false);
        updateUserAvailable(clientId, false).then(fetchClients());
    }

    const onEnableClick = () => {
        setEnabled(true);
        updateUserAvailable(clientId, true).then(fetchClients());
    }

    return (
        <div style={{padding: '40px 40px', justifyContent: 'center', width: '600px', textAlign: 'center'}}>
            <Row className="justify-content-center">
                <h2><span>Estado actual: </span>
                    <span
                        style={{color: enabled ? '#8cc63f' : '#dc3545'}}>{enabled ? 'Habilitado' : 'Deshabilitado'}</span>
                </h2>
            </Row>
            <br/>
            <Row>
                <p>
                    Si decides <span style={{fontWeight: 'bold'}}>habilitar el perfil, el cliente podrá gestionar sus reservas</span> sin
                    ningún inconveniente y
                    acceder a todos los servicios habituales.
                </p>
            </Row>
            <br/>
            <Row>
                <p>
                    Si decides <span style={{fontWeight: 'bold'}}>deshabilitar el perfil,</span> ten en cuenta lo
                    siguiente:
                </p>
            </Row>
            <br/>
            <Row>
                <p>
                    El cliente no podrá modificar ni gestionar tus reservas activas.<br/>
                    Transcurrido un tiempo, <span style={{fontWeight: 'bold'}}>todas tus reservas serán eliminadas automáticamente del calendario.</span>
                </p>
            </Row>
            <br/>
            <Row>
                <Col md={6}>
                    <div className={`item ${enabled ? 'selected' : ''}`}
                         onClick={() => onEnableClick()}>
                        <span>Habilitar</span>
                    </div>
                </Col>
                <Col md={6}>
                    <div className={`item ${!enabled ? 'selected' : ''}`}
                         onClick={() => onDisableClick()}>
                        <span>Deshabilitar</span>
                    </div>
                </Col>
            </Row>


        </div>
    )
}
export default EnabledProfile;