import {Card, Col, Row} from "react-bootstrap";
import optsIcon from "../../assets/añadir.svg";
import React, {useEffect, useState} from "react";
import '../CardListStyle.css';
import novaIcon from "../../assets/favicon.svg";
import getCenters from "../center/GetCenters";
import {getAllTrainers} from "../../services/ApiUsers";
import './Trainer.css';

const TrainerList = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const {centers, selectedCenter, setSelectedCenter} = getCenters();
    const [trainers, setTrainers] = useState([]);

    const handleIconClick = () => {
        setMenuVisible(!menuVisible);
    };

    const onEditClick = (trainer) => {
        console.log("Edit trainer", trainer);
    }

    useEffect(() => {
        let params = {
            centerId: selectedCenter ? selectedCenter.id : null
        }
        if (selectedCenter) {
            getAllTrainers(params)
                .then((data) => {
                    setTrainers(data)
                })
                .catch((error) => console.log('Error fetching trainers', error));
        }
    }, [selectedCenter]);

    return (
        <div className="services-main-container">
            <div className="centers-container">
                {centers.slice(0).map((center) => (
                    <div
                        key={center.id}
                        onClick={() => setSelectedCenter(center)}
                        className={`center-item ${selectedCenter === center ? 'selected' : ''}`}
                    >
                        {center.name}
                    </div>
                ))}
            </div>
            <div className="trainer-list-container">
                {/*<div className="row">*/}
                <Row className="trainer-list-row">
                    {trainers && trainers.map((trainer) => (
                        // <div className="col-12 col-md-6 mb-4" key={trainer.id}>
                        <Col key={trainer.id} xs={12} sm={6} md={4} lg={3}>
                            <Card className="text-center mb-4 card-custom" onClick={() => onEditClick(trainer)}>
                                <div className="icon-container" onClick={() => handleIconClick(trainer)}>
                                    <img src={optsIcon} alt="Add" className="opts-icon"/>
                                </div>
                                <Card.Img variant="top" src={trainer.image == null ? novaIcon : trainer.image}
                                          alt={trainer.name} className="card-img-top"/>
                                <Card.Body>
                                    <Card.Title>{trainer.name} {trainer.surname}</Card.Title>
                                    {/*<Card.Subtitle className="mb-2 text-muted">{service.center.tlf}<br />{service.center.email}</Card.Subtitle>*/}
                                    <Card.Text>
                                        {trainer.tlf}<br/>
                                        {trainer.email}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            {/*</div>*/}
                        </Col>
                    ))}
                    {/*</div>*/}
                </Row>
            </div>
        </div>
    );
};

export default TrainerList;
