import {Card} from "react-bootstrap";
import optsIcon from "../../../assets/puntitos.svg";
import novaIcon from "../../../assets/favicon.svg";
import React, {useState} from "react";

const ServiceList = ({selectedCenter, services, onEditClick}) => {
    const [menuVisible, setMenuVisible] = useState(false);

    const handleIconClick = () => {
        setMenuVisible(!menuVisible);
    };


    return (
        <div className="custom-list-container">

            <div className="row">
                {services && services.map((service) => (
                    <div className="col-12 col-md-6 mb-4" key={service.id}>
                        <Card className="text-center mb-4 card-custom service-card-custom"
                              onClick={() => onEditClick(service)} style={{
                            minHeight: '360px'
                        }}>
                            <div className="icon-container" onClick={() => handleIconClick(service)}>
                                <img src={optsIcon} alt="Add" className="opts-icon"/>
                            </div>
                            <Card.Img variant="top" src={service.image == null ? novaIcon : service.image}
                                      alt={service.name} className="card-img-top"/>
                            <Card.Body>
                                <Card.Title>{service.name}</Card.Title>
                                {/*<Card.Subtitle className="mb-2 text-muted">{service.center.tlf}<br />{service.center.email}</Card.Subtitle>*/}
                                {/*<Card.Text>*/}

                                {/*</Card.Text>*/}
                                <Card.Body style={{paddingTop: 'none'}}>
                                    {service.center.tlf}<br/>
                                    {service.center.email}<br/>
                                    {service.center.address}
                                    <div style={{
                                        justifyContent: 'space-between', alignItems: 'start',
                                        color: 'black', textAlign: 'left'
                                    }}>
                                        <hr/>
                                        <span style={{fontWeight: 'bold'}}>Duración:</span> {service.duration} mins
                                        <br/>
                                        <span style={{fontWeight: 'bold'}}>Entrenador: </span>{service.trainer.name}
                                    </div>
                                </Card.Body>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServiceList;