import getCenters from "../center/GetCenters";
import React, {useEffect, useState} from "react";
import {Calendar, momentLocalizer, Views} from "react-big-calendar";
import moment from 'moment';
import 'moment/locale/es'; // Importa los datos de localización en español
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarUser.css';
import {FaCircleChevronLeft, FaCircleChevronRight} from "react-icons/fa6";
import {getEvents} from "../../services/ApiClassSessions";
import ReservationForm from "./ReservationForm";

const customWeekdayFormat = (date) => {
    const weekdaysShort = ['L', 'M', 'X', 'J', 'V', 'S', 'D']; // Días en formato corto
    return weekdaysShort[moment(date).weekday()]; // Devuelve solo la primera letra del día
};

const formats = {
    dayFormat: (date, culture, localizer) => moment(date).format('dd'),
    weekdayFormat: customWeekdayFormat,
    dateFormat: 'D',
    // monthHeaderFormat: 'MMMM YYYY',
    monthHeaderFormat: (date, culture, localizer) => moment(date).format('MMMM YYYY'),
    weekHeaderFormat: (date, culture, localizer) => moment(date).format('MMMM YYYY'),
    timeGutterFormat: (date) => moment(date).format(' HH:mm '),
};

const messages = {
    allDay: 'Todo el día',
    previous: 'Anterior',
    next: 'Siguiente',
    today: 'Hoy',
    month: 'Mes',
    week: 'Semana',
    day: 'Día',
    agenda: 'Agenda',
    date: 'Fecha',
    time: 'Hora',
    event: 'Evento',
    noEventsInRange: 'No hay eventos en este rango de fechas',
    monday: 'Lunes',
    mon: 'Lun',
    showMore: (total) => `+ Ver más (${total})`,
};

const CalendarUser = () => {
    const {centers, selectedCenter, setSelectedCenter} = getCenters();
    const [show, setShow] = useState(false); // Estado para controlar la vista
    const [eventId, setEventId] = useState(null);
    const [events, setEvents] = useState([]);

    const fetchEvents = async () => {
        console.log('Fetching events...', selectedCenter);
        try {
            const eventsData = await getEvents({centerId: selectedCenter.id});
            const formattedEvents = eventsData.map(event => ({
                ...event,
                start: new Date(event.start), //moment(event.start).format('YYYY-MM-DDTHH:mm:ss'),
                end: new Date(event.end) //moment(event.end).format('YYYY-MM-DDTHH:mm:ss')
            }));
            setEvents(formattedEvents);
            console.log('Events:', formattedEvents);
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    };

    useEffect(() => {
        console.log('Selected center:', selectedCenter);
        if (selectedCenter !== null) {
            fetchEvents();
        }
    }, [selectedCenter]);

    const blockedDays = [
        new Date(2024, 9, 25), // 25 de octubre de 2024
        new Date(2024, 9, 31), // 31 de octubre de 2024
        // Agrega más fechas según sea necesario
    ];

    const handleSelectEvent = (event) => {
        setEventId(event.id);
        console.log('Selected event:', event);
        setShow(true);
    };

    const handleClose = () => {
        fetchEvents();
        setShow(false);
    }

    // Función para personalizar los estilos de los eventos
    const eventStyleGetter = (event) => {
        const backgroundColor = event.color || '#65b4bd'; // Usa el color del evento o un color por defecto
        const style = {
            backgroundColor,
            borderRadius: '5px',
            opacity: 0.8,
            color: 'white',
            border: '0px',
            display: 'block',
        };
        return {
            style,
        };
    };

    const dayStyleGetter = (date) => {
        const selectedDate = moment(date).startOf('day');

        if (blockedDays.some(blockedDate => moment(blockedDate).isSame(selectedDate))) {
            return {
                className: 'blocked', // Aplica la clase bloqueada si el día está en la lista
            };
        }
        return {};
    };

    moment.updateLocale('es', {
        weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        weekdaysShort: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        months: [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ],
        monthsWeekShort: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthsShort: [
            'ene', 'feb', 'mar', 'abr', 'may', 'jun',
            'jul', 'ago', 'sep', 'oct', 'nov', 'dic'
        ],
        week: {
            dow: 1, // Establecer lunes como el primer día
        }
    });


    const CustomToolbar = (props) => {
        const {onNavigate, onView, label} = props;

        const startOfWeek = moment(props.date).startOf('week'); // Ajustar según el primer día de la semana (default es domingo)
        const endOfWeek = moment(props.date).endOf('week');

        // Formatear los días y el mes
        const startDay = startOfWeek.format('dddd DD'); // Ejemplo: "Lunes 04"
        const endDay = endOfWeek.format('dddd DD'); // Ejemplo: "Domingo 10"
        const month = startOfWeek.format('MMMM'); // Ejemplo: "Noviembre"
        const year = startOfWeek.format('YYYY'); // Ejemplo: "2024"
        let dateLabel = `${startDay} - ${endDay} de ${month} de ${year}`;
        if (props.view === Views.MONTH) {
            dateLabel = `${month} ${year}`
        }

        return (
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                margin: '0.8rem 0'
            }}>

                <div style={{display: 'flex', gap: '10px'}}>
                    <FaCircleChevronLeft onClick={() => props.onNavigate('PREV')}
                                         style={{width: '30px', height: '1.5rem', color: '#202020'}}/>
                    <FaCircleChevronRight onClick={() => props.onNavigate('NEXT')}
                                          style={{width: '30px', height: '1.5rem', color: '#202020'}}/>
                </div>

                <span style={{fontWeight: 'bold'}}>
                    {dateLabel}
                </span>
                <div style={{display: 'flex', gap: '10px'}}>

                </div>

            </div>
        );
    }

    const localizer = momentLocalizer(moment)
    return (
        <div>
            <Calendar localizer={localizer}
                      events={events}
                      selectable
                      startAccessor="start"
                      endAccessor="end"
                      messages={messages}
                      views={['month']}
                      defaultView={'month'}
                      onSelectEvent={handleSelectEvent}
                      style={{height: 600}}
                      eventPropGetter={eventStyleGetter}
                      formats={formats}
                      dayPropGetter={dayStyleGetter}
                      className="nova-calendar"
                      components={{
                          toolbar: CustomToolbar,
                      }}
            />
            <ReservationForm eventId={eventId} show={show} handleClose={handleClose}/>
            {/*<NovaCalendar />*/}
        </div>
    );
};
export default CalendarUser;
