import React from "react";
import {FaUserCircle} from "react-icons/fa";
import './HeaderBar.css';
import {Dropdown} from "react-bootstrap";
import LogoutButton from "../../security/Logout";
import logo from "../../assets/LOGO_NOVA_BLACK-33.svg";

export const HeaderBar = ({selectedPage, isMobile}) => {
    let name = localStorage.getItem("name");
    let surname = localStorage.getItem("surname");

    return <>
        {/*<div className={`main-content ${collapsed ? "expanded" : ""}`}>*/}
        {/* Sección fija de encabezado */}
        <header>
        <div className="header-bar header d-flex justify-content-between align-items-center p-3">
            {isMobile ? (
                <img src={logo} alt="Logo" className="header-logo"/>
            ) : (
                <h2/>
            )}
            {/*{isMobile ? (<img src={logo} alt="Logo" className="header-logo" /> : null}*/}
            {/*<h2 className={"d-flex"}>{selectedPage}</h2>*/}
            <div className="profile-section d-flex align-items-center">
                {/*<FaUserCircle size={30}/>*/}
                {/*<span className="user-name ms-2">{name} {surname}</span>*/}
                <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic"
                                     className="d-flex align-items-center custom-dropdown-toggle">
                        <FaUserCircle size={30}/>
                        {!isMobile ? (
                            <span className="user-name ms-2" style={{textDecoration: 'none'}}>{name} {surname}</span>
                        ) : (<span/>)
                        }
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="custom-dropdown-menu">
                        <Dropdown.Item as="button">
                            <LogoutButton/>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
        </header>
    </>
};