import {Card} from "react-bootstrap";
import optsIcon from "../../../assets/puntitos.svg";
import React, {useState} from "react";
import '../../CardListStyle.css';
import novaIcon from "../../../assets/favicon.svg";

const BonoList = ({selectedCenter, bonos, onEditClick}) => {
    const [menuVisible, setMenuVisible] = useState(false);

    const handleIconClick = () => {
        setMenuVisible(!menuVisible);
    };


    return (
        <div className="bono-list-container">
            <div className="row">
                {bonos && bonos.map((bono) => (
                    <div className="col-12 col-md-6 mb-4" key={bono.id}>
                        <Card className="text-center mb-4 card-custom card-custom" onClick={() => onEditClick(bono)}>
                            <div className="icon-container" onClick={() => handleIconClick(bono)}>
                                <img src={optsIcon} alt="Add" className="opts-icon"/>
                            </div>
                            <Card.Img variant="top" src={bono.image == null ? novaIcon : bono.image}
                                      alt={bono.name} className="card-img-top"/>
                            <Card.Body>
                                <Card.Title>{bono.name}</Card.Title>
                                {/*<Card.Subtitle className="mb-2 text-muted">{service.center.tlf}<br />{service.center.email}</Card.Subtitle>*/}
                                <Card.Text>
                                    {bono.center.tlf}<br/>
                                    {bono.center.email}<br/>
                                    {bono.center.address}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BonoList;
