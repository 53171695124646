import apiFetch from "./api";

const apiUrl = `${process.env.REACT_APP_SERVER_URL}/center`;

const getAllCenters = async () => {
    // const queryString = "?" + new URLSearchParams(params).toString();
    // const queryString = Object.keys(params).length ? "?" + new URLSearchParams(params).toString() : "";
    const response = await apiFetch(`${apiUrl}`, { //${queryString}`, {
        method: 'GET',
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const getCenterById = async (id) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {method: 'GET', redirect: "manual", credentials: 'include'});
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const createCenter = async (bono) => {
    const response = await apiFetch(apiUrl, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(bono),
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const updateCenter = async (id, bono) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(bono),
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const deleteCenter = async (id) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {
        method: 'DELETE',
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

export {getAllCenters, getCenterById, createCenter, updateCenter, deleteCenter};