import React, {useState} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import './CenterList.css';
import centerImg from "../../assets/e-motion.jfif";
import optsIcon from "../../assets/puntitos.svg";
import getCenters from "./GetCenters";

const CenterList = () => {

    const {centers, selectedCenter, setSelectedCenter} = getCenters();
    const [menuVisible, setMenuVisible] = useState(false);

    const handleIconClick = (center) => {
        setSelectedCenter(center);
        setMenuVisible(!menuVisible);
    };

    /*const [centers, setCenters] = useState();
    const [menuVisible, setMenuVisible] = useState(false);
    const [selectedCenter, setSelectedCenter] = useState(null);

    const getCenters = async () => {
        const apiUrl = `${process.env.REACT_APP_SERVER_URL}/center`;
        console.log(apiUrl);
        console.log(process.env.REACT_APP_SERVER_URL)

        const response = await fetch(
            apiUrl,
            {method: 'GET', redirect: "manual", credentials: 'include'}
        ); // .then((response) => response);

        if (response.type === 'opaqueredirect') {
            // Manejar la redirección manualmente
            window.location.href = response.url;
            return;
        }

        console.log(response)
        // if (response.redirected || response) {
        //     document.location = response.url;
        // }
        const data = await response.json();
        setCenters(data)
    }

    useEffect(() => {
        getCenters();
    }, []);

    const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };

    const handleIconClick = (center) => {
        setSelectedCenter(center);
        setMenuVisible(!menuVisible);
    };*/

    return (
        <Row className="center-list-row">
            {/*<Row style={{ display: 'flex', flexWrap: 'wrap' }}>*/}
            {centers && centers.map((center) => (
                <Col key={center.id} xs={12} sm={6} md={4} lg={3}>
                    {/*<Col key={center.id} xs={2} sm={2} md={2} lg={2} xl={2}>*/}
                    <Card className="text-center mb-4 card-custom">
                        <div className="icon-container" onClick={() => handleIconClick(center)}>
                            <img src={optsIcon} alt="Add" className="opts-icon"/>
                        </div>
                        <Card.Img
                            variant="top"
                            src={centerImg} // Use the imported image
                            alt={center.name}
                            className="card-img-top"
                        />
                        {/*<Card.Img
                                variant="top"
                                src={`data:image/jpeg;base64,${center.image}`}
                                // src={`data:image/jpeg;base64,${arrayBufferToBase64(center.image)}`}
                                alt={center.name}
                            />*/}
                        <Card.Body>
                            <Card.Title>{center.name}</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">{center.email}<br/>{center.tlf}
                            </Card.Subtitle>
                            <Card.Text>
                                {center.address}
                            </Card.Text>
                        </Card.Body>
                        {menuVisible && selectedCenter === center && (
                            <div className="menu">
                                <ul>
                                    <li>Option 1</li>
                                    <li>Option 2</li>
                                    <li>Option 3</li>
                                </ul>
                            </div>
                        )}
                    </Card>
                </Col>
            ))}
        </Row>
    )
};

export default CenterList;

