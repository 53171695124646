import apiFetch from "./api";

const apiUrl = `${process.env.REACT_APP_SERVER_URL}/service`;

const getAllServices = async (params = {}) => {
    // const queryString = new URLSearchParams(params).toString();
    const queryString = Object.keys(params).length ? "?" + new URLSearchParams(params).toString() : "";
    const response = await apiFetch(`${apiUrl}${queryString}`, {
        method: 'GET',
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const getServiceById = async (id) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {method: 'GET', redirect: "manual", credentials: 'include'});
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const createService = async (service) => {
    const response = await apiFetch(apiUrl, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(service),
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const updateService = async (id, service) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(service),
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const deleteService = async (id) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {
        method: 'DELETE',
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

export {getAllServices, getServiceById, createService, updateService, deleteService};