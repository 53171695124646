const apiFetch = async (url, options = {}) => {
    const accessToken = localStorage.getItem('access_token');
    // console.log("API FETCH START -> ", url);
    if (accessToken === null) {
        window.location.href = '/login';

    }
    const headers = {
        ...options.headers,
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
    };

    const response = await fetch(url, {
        ...options,
        headers,
    });

    // console.log("API FETCH END -> ", response);
    // console.log("API FETCH END -> ", response.status);
    if (response.status === 401) {
        console.log("401");
        window.location.href = '/login';

    }
    if (!response.ok) {
        throw new Error('Network response was not ok', response);
    }

    return response;
};

export default apiFetch;