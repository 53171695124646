import apiFetch from "./api";

const apiUrl = `${process.env.REACT_APP_SERVER_URL}/class-sessions`;

const getEvents = async (params = {}) => {
    // const queryString = "?" + new URLSearchParams(params).toString();
    const queryString = Object.keys(params).length ? "?" + new URLSearchParams(params).toString() : "";
    const response = await apiFetch(`${apiUrl}/events${queryString}`,
        {
            method: 'GET',
            redirect: "manual",
            credentials: 'include'
        });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
}

const cancelSession = async (userId, eventId) => {

    const response = await apiFetch(`${apiUrl}/${eventId}/cancel/user/${userId}`, {
        method: 'DELETE',
        redirect: "manual",
        credentials: 'include'
    });
}

const getSessionById = async (id) => {
    console.log('Fetching session...', id);
    const response = await apiFetch(`${apiUrl}/${id}`, {method: 'GET', redirect: "manual", credentials: 'include'});
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};
const postSession = async (body) => {
    const response = await apiFetch(`${apiUrl}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body),
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const putSession = async (id, body) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body),
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response.json();
};

const putSerie = async (id, body) => {
    const response = await apiFetch(`${apiUrl}/serie/${id}`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body),
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response; //.json();
};

const deleteSession = async (id) => {
    const response = await apiFetch(`${apiUrl}/${id}`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response; //.json();
};

const deleteSerie = async (id) => {
    const response = await apiFetch(`${apiUrl}/serie/${id}`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        redirect: "manual",
        credentials: 'include'
    });
    if (response.type === 'opaqueredirect') {
        window.location.href = response.url;
        return;
    }
    return await response; //.json();
};

export {getEvents, postSession, getSessionById, putSerie, putSession, deleteSession, deleteSerie, cancelSession};
