import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import authService from './AuthService';

const Callback = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAccessToken = async () => {
            const params = new URLSearchParams(location.search);
            console.log("callback ", location.search);
            let code = params.get('code');
            if (code) {
                try {
                    let data = await authService.getAccessToken(code);
                    if (data) {
                        window.location.href = '/centers';
                    }
                    code = null;

                } catch (error) {
                    console.error('Callback: Error fetching access token:', error);
                }
            } else {
                console.log("No code in callback");
                window.location.href = '/login';
            }
        };

        fetchAccessToken();
    }, [location, navigate]);

};

export default Callback;